import React from 'react';
import styled from 'styled-components';
import { Link, graphql } from 'gatsby';
import { Layout } from '../components/layout';
import { Seo } from '../components/seo';
import { Content } from '../components/content';
import {
    Post,
    Thumbnail,
    PostContent,
    PostTitle,
    PostExcerpt,
    PostDate
} from './posts';

const AllTags = styled(Link)`
    font-size: 18px;
    display: inline-block;
    color: ${({ theme }) => theme.colors.bodyColor};
    transition: transform .2s;

    &:hover {
        transform: translateX(-5px);
    }
`;

const Footer = styled.div`
    display: flex;
    width: 100%;
`;

const TagTemplate = ({ data, pageContext }) => {
    const siteTitle = pageContext.tag;
    const posts = data.allGoogleDocs.edges;

    return (
        <Layout>
            <Seo
                title={siteTitle}
                keywords={[pageContext.tag]}
            />
            <Content>
                {posts.map(({ node }) => (
                    <Post to={node.slug} key={node.slug}>
                        {
                            node.cover?.image?.childImageSharp?.gatsbyImageData && (
                                <Thumbnail image={`url(${node.cover.image.childImageSharp.gatsbyImageData.images.fallback.src})`} />
                            )
                        }
                        <PostContent>
                            <PostTitle>{node.name}</PostTitle>
                            <PostExcerpt>{node.summary}</PostExcerpt>
                            <PostDate>{node.date}</PostDate>
                        </PostContent>
                    </Post>
                ))}
                <Footer>
                    <nav role='navigation'>
                        <ul>
                            <p>
                                <AllTags to='/tags'>← All tags</AllTags>
                            </p>
                        </ul>
                    </nav>
                </Footer>
            </Content>
        </Layout>
    );
};

export default TagTemplate;

export const pageQuery = graphql`
    query pagPageQuery($tag: String) {
        allGoogleDocs(
            sort: { fields: date, order: DESC }
            filter: { tags: { in: [$tag] } }
        ) {
            totalCount
            edges {
                node {
                    slug
                    date(formatString: "YYYY, MMM DD")
                    name
                    cover {
                        image {
                            childImageSharp {
                                gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, formats: [AUTO, AVIF, WEBP])
                            }
                        }
                    }
                    summary
                }
            }
        }
    }
`;
